import { yupResolver } from '@hookform/resolvers/yup';
import {
  alpha,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  Typography,
  Alert,
  Snackbar,
  useTheme,
  Select,
  FormControl,
  MenuItem,
  Chip,
  OutlinedInput,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import DOMPurify from 'dompurify'; // If sanitizing
import { RHFTextField } from 'src/components/hook-form';
import FormProvider from 'src/components/hook-form/form-provider';
import { useSettingsContext } from 'src/components/settings';
import RHFMarkdownEditor from 'src/components/markdown-editor/markdownEditor';
import RHFImageUpload from 'src/components/hook-form/rhf-image-upload';
import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import { addPackage, getPackageDetails, updatePackage } from 'src/services/package.service';
import { getClinicDoctors } from 'src/services/clinic.service';
import { useParams } from 'src/routes/hooks';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function EditClinicPackageView() {
  const settings = useSettingsContext();
  const { user } = useAuthContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const [avalibleDoctors, setAvalibleDoctors] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State to control Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const [loading, showLoading] = useState(true); // State for
  const params = useParams();
  console.log(params);

  const packageSchema = Yup.object().shape({
    package_name: Yup.string().required('Package name is required'),
    price: Yup.number()
      .required('Price is required')
      .positive('Price must be positive')
      .min(0, 'Price cannot be negative'),
    procedure: Yup.string().required('Procedure is required'),
    package_promo_image: Yup.array().of(Yup.string().nullable()),
    package_description: Yup.string(),
    pre_post_images: Yup.array().of(Yup.string()).nullable(),
    package_inclusions: Yup.array().of(Yup.string()).nullable(),
    package_exclusions: Yup.array().of(Yup.string()).nullable(),
    package_doctors: Yup.array().of(Yup.string()).nullable(),
  });

  const packageDefaultValues = {
    package_name: '',
    price: 0,
    procedure: '',
    package_doctors: [],
    package_promo_image: [],
    package_description: '',
    pre_post_images: [],
    package_inclusions: [],
    package_exclusions: [],
  };

  const { control, reset, watch, setValue, handleSubmit } = useForm({
    resolver: yupResolver(packageSchema),
    defaultValues: packageDefaultValues,
  });

  useEffect(() => {
    showLoading(true);
    getPackageDetails(params.packageId)
      .then((data) => {
        getClinicDoctors(user.clinic_id)
          .then((doctorsList) => {
            setAvalibleDoctors(doctorsList);
          })
          .catch((error) => {
            setSnackbarMessage(error.message);
            setSnackbarOpen(true);
            setSnackbarSeverity('error');
          });
        console.log(data);
        reset({
          package_name: data.package_name,
          price: data.price,
          procedure: data.procedure,
          package_promo_image: data.package_promo_image,
          package_description: data.package_description && data.package_description.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>'),
          pre_post_images: data.pre_post_images,
          package_inclusions: data.package_inclusions,
          package_exclusions: data.package_exclusions,
          package_doctors: data.package_doctors,
        });
        showLoading(false);
      })
      .catch((err) => {
        setSnackbarMessage(err.message);
        setSnackbarOpen(true);
        setSnackbarSeverity('error');
      });
  }, [user.clinic_id, params.packageId, reset]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const packagePromoImage = watch('package_promo_image');
  const prePostImages = watch('pre_post_images');
  const packageDescription = watch('package_description');
  const fileName = `package_promo_${new Date().toISOString()}`;
  const folderName = `images/packages/${user.clinic_id}`;

  const prePostImagesFileName = `package_pre_post_images_${new Date().toISOString()}`;
  const prePostImagesFolderName = `images/packages/pre_post_images/${user.clinic_id}`;

  const onSubmit = (data) => {
    console.log(data);
    const finalData = {
      clinic_id: user.clinic_id,
      ...data,
    };
    updatePackage(params.packageId, finalData)
      .then((response) => {
        console.log('Package Updated', response);
        reset();
        setSnackbarMessage('Package updated successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.log('Error:', error.message);
        setSnackbarMessage(`Error: ${error.message}`);
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  if (loading) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container
      sx={{
        background: { lg: 'white' },
        margin: 4,
        marginX: 'auto',
        padding: 3,
        maxWidth: 840,
        borderRadius: 4,
      }}
    >
      <Stack direction="row" gap="14px" alignItems="center" sx={{ marginBottom: '14px' }}>
        <IconButton
          aria-label="back-button"
          size="large"
          onClick={() => {
            navigate(-1);
          }}
        >
          <Iconify icon="tabler:chevron-left" />
        </IconButton>
        <Typography variant="h4"> Create Your Package here </Typography>
      </Stack>

      <FormProvider onSubmit={handleSubmit(onSubmit)} methods={{ control, handleSubmit }}>
        <Grid container spacing={{ xs: '14px', lg: '44px' }}>
          <Grid item xs={12} lg={6}>
            <RHFTextField
              name="package_name"
              label="Package Name"
              margin="dense"
              variant="outlined"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="package_doctors"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel id="roles">Doctors</InputLabel>
                  <Select
                    {...field}
                    label="Doctors"
                    id="doctors"
                    margin="dense"
                    multiple
                    fullWidth
                    placeholder="select a Doctor"
                    input={<OutlinedInput label="Doctor" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((id) => {
                          const doctor = avalibleDoctors.find((doc) => doc.user_id.id === id);
                          return (
                            <Chip
                              key={id}
                              label={`${doctor?.user_id.first_name} ${doctor?.user_id.last_name}`}
                            />
                          );
                        })}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {avalibleDoctors.map((doctorDetails) => (
                      <MenuItem
                        key={doctorDetails.user_id.id}
                        value={doctorDetails.user_id.id} // Use ID as value
                        style={getStyles(
                          `${doctorDetails.user_id.first_name} ${doctorDetails.user_id.last_name}`,
                          field.value,
                          theme
                        )}
                      >
                        {`${doctorDetails.user_id.first_name} ${doctorDetails.user_id.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: '14px' }} variant="h6">
              Package Description
            </Typography>
            <RHFMarkdownEditor
              name="package_description"
              placeholder="Enter Package Description"
              defaultValue={packageDescription}
            />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField name="procedure" label="Procedure" margin="dense" variant="outlined" />
          </Grid>
          <Grid item xs={6}>
            <RHFTextField
              name="price"
              label="Price"
              margin="dense"
              variant="outlined"
              type="number"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFImageUpload
              initialImage={packagePromoImage}
              fileName={fileName}
              setValue={setValue}
              name="package_promo_image"
              folderName={folderName}
              displayImages
              multipleFiles
              maxFiles={1}
              label="Package Promo Image"
              variant="outlined"
              width={1530}
              height={750}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <RHFImageUpload
              initialImage={prePostImages}
              fileName={prePostImagesFileName}
              setValue={setValue}
              name="pre_post_images"
              folderName={prePostImagesFolderName}
              filesRequired={2}
              displayImages
              multipleFiles
              maxFiles={10}
              label="Pre/Post Images"
              variant="outlined"
              width={172}
              height={240}
              required
            />
          </Grid>
          <Grid container item alignItems="end" xs={12} justifyContent="flex-end">
            <Button
              variant="contained"
              sx={{
                width: 1,
                boxShadow: 10,
                fontSize: { lg: '16px', xs: '12px' },
                background: '#D92D7F',
                ':hover': { background: '#D92D7F' },
              }}
              type="submit"
            >
              Save Package
            </Button>
          </Grid>
        </Grid>
      </FormProvider>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
